& {
  background-image: url($storage-url + '/background_section_budget.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 548px;

  &__divisor {
    display: flex;
    padding: 0 0 35px;
  }

  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6px;
    min-height: 190px;
  }

  &__title {
    line-height: $line-height-m;
    font-weight: $font-weight-bold;
    margin-bottom: $andes-spacing-8;
    color: $andes-white;
  }

  &__description {
    font-size: $font-size-24;
    font-weight: $font-weight-regular;
    line-height: $andes-spacing-24;
    margin-bottom: $andes-spacing-24;
    color: $andes-white;
  }

  &__action {
    @include ads-white-button;

    color: $andes-blue-500;
    margin-top: 55px;
  }
}
