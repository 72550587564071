.testimonials__item {
  @import './common';

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 80px 75px;

  &__content {
    width: 510px;
    margin-right: 90px;
  }

  &__content-description {
    font-size: 24px;
    line-height: 32px;
  }

  &__content-subdescription {
    font-size: 24px;
    line-height: 32px;
  }

  &__content-subdescription-percentaje {
    font-size: 28px;
    line-height: 24px;
  }

  &__image {
    max-width: 300px;
    max-height: 300px;
  }
}
