.faqs {
  @import '../../Accordion/Accordion.style';
  @import './common';

  margin-bottom: 65px;

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 32px;
  }

  &__content-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 22px;
    margin-left: 20px;
  }

  &__content-list-item-icon {
    max-width: 18px;
    max-height: 19px;
  }

  &__content-list-item-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-left: 14px;
  }

  &__content-item-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-left: 20px;
  }

  .accordion-item {
    width: 870px;
  }

  .accordion-item__title,
  .accordion-item__selected-title {
    color: $andes-gray-900;
    font-weight: $font-weight-bold;
    font-size: $font-size-20;
    line-height: 20px;
  }
}
