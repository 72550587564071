.placements-products {
  @import './common';
  @import '../Item/styles/index.mobile';

  padding-top: 40px;
  position: relative;
  top: -100px;
  z-index: 9;
  margin-bottom: -120px;

  &__content {
    max-width: 100% !important;
  }

  &__container_carousel {
    margin: 0 8px 60px 0;
  }

  &__title {
    width: 280px;
  }

  .andes-carousel-snapped__control {
    display: none !important;
  }

  &__title {
    font-size: $font-size-24;
  }

  &__description {
    font-size: $font-size-18;
    font-weight: $font-weight-regular;
    line-height: $line-height-m;
  }
}
