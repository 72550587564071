.section-daily-budget {
  @import './common';

  position: relative;
  z-index: 7;
  top: -74px;
  margin-bottom: -74px;

  &__title {
    font-size: $font-size-44;
    line-height: $andes-spacing-48;
    margin-bottom: 4px;
  }

  &__description {
    font-weight: $font-weight-regular;
    max-width: 690px;
    margin-top: 4px;
  }

  @media screen and (width >= 1400px) {
    &__container {
      max-width: 1400px;
    }
  }
}
