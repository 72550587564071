.wrapper-config .acquisition {
  @import './AcquisitionContent.global';

  // TODO importar las clases de los componentes aquí
  // @import '../../components/HeaderAcquisition/Header.desktop.scss';
  @import '../../components/Hero/styles/index.desktop';
  @import '../../components/DailyBudget/styles/index.desktop';
  @import '../../components/Placements/styles/index.desktop';
  @import '../../components/SocialPreFooter/styles/index.desktop';
  @import '../../components/Testimonials/styles/index.desktop';
  @import '../../components/Faqs/styles/index.desktop';
  @import '../../components/TopMessage/styles/index.desktop';

  // TODO importar las clases de los componentes aquí
}

@media only screen and (max-width: $breakpoint-desktop) {
  @import './AcquisitionContent.mobile';
}
