.social-pre-footer {
  @import './common';

  &__content-mobile {
    background: $ads-primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
  }

  &__content-desktop {
    display: none;
  }

  &__content-description {
    color: $andes-white;
    max-width: 288px;
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: $font-weight-bold;
      font-size: 25px;
      line-height: 25px;
    }

    &-sellers {
      font-weight: 900;
      font-size: 36px;
      line-height: 36px;
      padding-top: 15px;
    }

    &-subdesc {
      font-weight: $font-weight-regular;
      font-size: $font-size-18;
      line-height: 24px;
      padding-top: 15px;
    }
  }

  &__content-images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  &__content-image {
    max-width: 180px;
    max-height: 180px;
  }

  &__action {
    @include ads-white-button;

    margin-top: 14px;
    color: $andes-blue-500;
  }
}
