.testimonials {
  @import './common';
  @import '../Item/styles/index.desktop';

  min-height: 822px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -85px;
  margin-bottom: -60px;

  &__title {
    max-width: 690px;
    font-weight: $font-weight-bold;
    font-size: $font-size-32;
    line-height: 32px;
  }

  &__container-carousel {
    margin-top: 30px;
    margin-bottom: 120px;
  }

  &__content {
    .andes-carousel-snapped__container {
      max-width: 1050px;
      max-height: 430px;
    }

    .andes-carousel-snapped--scroll-visible {
      overflow-x: hidden;
    }
  }

  &__divisor {
    margin-top: 60px;
    margin-bottom: 32px;
  }
}
