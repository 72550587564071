.section-hero {
  @import './common';

  background-image: url($storage-url + '/background_hero_wave.svg');
  min-height: 752px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  z-index: 9;

  &__container {
    flex-direction: row;
    justify-content: space-around;
  }

  &__image {
    max-width: min(100%, 488px);
    max-height: min(100%, 272px);

    &-container {
      overflow: hidden;
      width: 550px;
    }
  }

  &__content {
    margin-right: 10px;
    padding-left: 180px;
    max-width: 510px;

    &__title {
      font-size: $font-size-40;
    }

    &__description {
      font-weight: $font-weight-regular;
      max-width: 400px;
    }

    &__action {
      align-self: flex-start;
    }
  }

  @media screen and (width >= 1400px) {
    &__container {
      max-width: 1400px;
    }
  }
}
