@import '@andes/message/index';

.section-top-message {
  &__image-matching {
    width: 24px;
    height: 33px;
  }

  &__image-bonus {
    width: 28px;
    height: 28px;
  }

  &__body {
    display: flex;
    gap: $andes-spacing-12;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-18;
    line-height: 18px;
  }

  &__description {
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: 18px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__action {
    @include ads-white-button;

    color: $andes-blue-500;
  }

  .andes-message {
    padding: $andes-spacing-16 $andes-spacing-24;
  }

  .andes-message-noborder {
    border-radius: 0;
    background: #3f167a;

    .andes-badge {
      display: none !important;
    }
  }

  .andes-message__badge--neutral {
    display: none;
  }

  .andes-message__text {
    display: flex;
    justify-content: center;
    gap: $andes-spacing-24;
  }

  .andes-message__content {
    justify-content: center;
  }
}
