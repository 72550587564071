$ads-desktop-max-width: 1366px;
$ads-mobile-max-width: 360px;
$font-size-40: 40px;
$font-size-36: 36px;
$ads-primary: #ab86ff;
$ads-primary-shadow: #2d3277;
$ads-yellow: #ffe600;
$ads-yellow-shadow: #edd600;
$storage-url: 'https://http2.mlstatic.com/storage/a-landings';

@mixin section {
  width: $breakpoint-desktop;
  margin: $andes-spacing-20 auto;
}
@mixin section-mobile {
  width: auto;
  margin: $andes-spacing-20 $andes-spacing-32;
}
@mixin cards-content {
  background: $andes-white;
  box-shadow: 0 6px 16px rgb(0 0 0 / 10%);
  border-radius: 18px !important;
  padding: 0 !important;
  min-height: 60px;
}
@mixin ads-button($primary, $shadow, $text) {
  background-color: $primary;
  color: $text;

  &:hover {
    background-color: $shadow;
    color: $text;
  }

  &:focus {
    box-shadow: none;
    border: 2px solid $primary;
    background-color: $shadow;
    color: $text;
  }
}

@mixin ads-white-button {
  @include ads-button($andes-white, $andes-white, $andes-blue-400);
}

@mixin ads-purple-button {
  @include ads-button($ads-yellow, $ads-yellow-shadow, $andes-gray-900);
}
