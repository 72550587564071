.faqs {
  @import '../../Accordion/Accordion.style';
  @import './common';

  margin-bottom: 25px;

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__content-list-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 24px;
  }

  &__content-list-item-icon {
    max-width: 20px;
    max-height: 21px;
    padding-top: 3px;
  }

  &__content-list-item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-left: 14px;
  }

  &__content-item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-left: 0;
  }

  .accordion-item {
    width: 290px;
  }

  .accordion-item__card-header {
    padding: 16px 16px 16px 6px !important;
  }

  .accordion-item__title,
  .accordion-item__selected-title {
    color: $andes-gray-900;
    font-weight: $font-weight-bold;
    font-size: $font-size-18;
    line-height: 18px;
  }
}
