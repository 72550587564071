.placements-products {
  @import './common';
  @import '../Item/styles/index.desktop';

  padding-top: 80px;
  top: -155px;
  z-index: 8;
  margin-bottom: -155px;
  position: relative;
  min-height: 878px;

  &__divisor {
    margin: 40px 0 24px;
  }

  &__title {
    max-width: 690px;
  }

  &__container_carousel {
    margin-bottom: 60px;
  }
}
