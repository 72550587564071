& {
  @import '../../styles/common';
  @import '@andes/button/index';
  @import '@andes/carousel-snapped/index';

  color: $andes-gray-900;

  * {
    margin: 0;
    padding: 0;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
