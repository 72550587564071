&__item {
  @import './common';

  padding: 15px 10px;
  flex-direction: column !important;
  align-items: center !important;

  &_image {
    width: 268px;
    height: 193px !important;
  }

  &_content {
    width: 100%;
    min-height: 320px;

    &__title {
      margin-top: 24px;
      text-align: center;
      padding: 4px 20px;
      font-size: 20px;
    }

    &__description {
      text-align: center;
      padding-left: 12px;
      padding-right: 12px;
      min-height: 92px;
    }

    &__subdescription {
      margin-top: auto;
      margin-bottom: 15px;
      justify-content: center;
      font-size: 12px;
    }
  }
}
