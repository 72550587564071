$andes-theme: 'mercadolibre';

@import '@andes/common/index';
@import '@andes/snackbar/index';

html {
  font-size: 16px;
  font-family: $font-family-primary;
}

body {
  border-collapse: collapse;
  background-color: $andes-white;
}

@import './_variables';

.nav-logo {
  background-image: url($storage-url + '/logo_yellow__large.png') !important;
  background-size: contain;
  @media only screen and (width <= 1024px) {
    background-image: url($storage-url + '/logo_yellow__small.png') !important;
  }
}
