.testimonials__item {
  @import './common';

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 40px 24px;

  &__content {
    text-align: center;
    padding-top: 32px;
    max-width: 240px;
  }

  &__content-description {
    font-size: 18px;
    line-height: 24px;
  }

  &__content-subdescription {
    align-items: flex-start;
    justify-content: center;
    font-size: 18px;
    line-height: 24px;
  }

  &__content-subdescription-percentaje {
    font-size: 22px;
    line-height: 24px;
    padding-top: 2px;
  }

  &__image {
    max-width: 150px;
    max-height: 150px;
  }
}
