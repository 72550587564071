& {
  background-image: url($storage-url + '/testimonials-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  overflow: hidden;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
