& {
  text-align: center;

  &__container {
    height: 41px;
  }

  &__icon {
    margin-top: 1px;
    cursor: pointer;
  }
}
