&__item {
  @import './common';

  width: 100%;
  margin-top: $andes-spacing-28;
  padding: 40px 0;

  &_image {
    width: 556px;
    height: 400px !important;
    margin: 10px 30px;
    padding: 0;

    img {
      width: 552px;
      height: 397px !important;
    }
  }

  &_content {
    width: 300px;
    padding: 15px 10px;
    margin-right: 5px;

    &__title {
      margin-top: 50px;
    }

    &__description {
      margin-top: 30px;
    }

    &__subdescription {
      margin-top: 30px;
    }
  }
}
