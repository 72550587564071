.landing-acquisition-modal {
  width: 506px !important;
  height: 415px !important;
  border-radius: 6px;

  .andes-modal__header {
    padding: 32px;
    padding-bottom: 0 !important;
  }

  .andes-modal__content {
    padding: 0 32px 32px;
    text-align: center;
  }

  .andes-modal-dialog__container {
    position: unset;

    .andes-modal-dialog__content {
      padding: 32px;
    }
  }

  .andes-modal-dialog__button-close {
    top: 16px;
    right: 32px;

    &::before,
    &::after {
      background-color: $andes-gray-550;
    }
  }

  .acquisition-modal-img-content {
    display: flex;

    .acquisition-modal-img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .acquisition-modal-title {
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
    text-align: center;
    color: $andes-gray-900;
  }

  .acquisition-modal-description {
    margin-top: 9px;
    font-size: $font-size-16 !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: $andes-gray-900;
    line-height: 1.875rem !important;
  }

  .acquisition-modal-button {
    margin-top: 25px;

    .andes-button {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
