& {
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-image: url($storage-url + '/bg_placements.svg');
  padding-bottom: 140px;
  height: auto;
  overflow: hidden;
  background-position: center bottom;

  &__container,
  &__container_carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }

  &__content {
    display: flex;
    max-width: 1024px !important;
    height: auto;

    .andes-carousel-snapped__container {
      max-width: 100% !important;
      max-height: 480px !important;
    }

    .andes-carousel-snapped__slide--next,
    .andes-carousel-snapped__slide {
      opacity: 0.3;
    }

    .andes-carousel-snapped__slide--active {
      opacity: 1;
    }

    .andes-carousel-snapped__pagination--position-bottom {
      margin-top: 24px;
    }

    .andes-carousel-snapped__pagination-item--active button {
      background-color: #3483fa !important;
    }

    .andes-carousel-snapped__pagination-item button {
      background-color: rgb(0 0 0 / 25%);
      border-color: rgb(0 0 0 / 25%);
    }
  }

  &__divisor {
    padding: 24px 0;
  }

  &__title {
    font-size: $font-size-32;
    font-weight: $font-weight-bold;
    line-height: $line-height-xs;
    padding: 5px 10px;
  }

  &__description {
    font-size: $font-size-18;
    font-weight: $font-weight-regular;
    line-height: $line-height-m;
  }
}
