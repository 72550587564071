& {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  &__container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 6px;
  }

  &__image {
    &-container {
      @import './animation';

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    &__title {
      font-weight: $font-weight-semibold;
      line-height: $line-height-xs;
      margin-bottom: $andes-spacing-8;
    }

    &__description {
      font-size: $font-size-18;
      font-weight: $font-weight-light;
      line-height: $line-height-m;
      margin-bottom: $andes-spacing-24;
    }
  }
}
