.social-pre-footer {
  @import './common';

  &__content-desktop {
    background: $ads-primary;
    display: flex;
    justify-content: center;
    padding-top: 145px;
  }

  &__content-mobile {
    display: none;
  }

  &__content-image {
    max-width: 350px;
    max-height: 350px;
  }

  &__content-description {
    color: $andes-white;

    &-title {
      font-weight: $font-weight-bold;
      font-size: $font-size-32;
      line-height: 32px;
    }

    &-sellers {
      font-weight: $font-weight-bold;
      font-size: $font-size-44;
      line-height: 44px;
      padding-top: 15px;
    }

    &-subdesc {
      font-weight: $font-weight-regular;
      font-size: $font-size-24;
      line-height: 24px;
      padding-top: 4px;
    }
  }

  &__action {
    @include ads-white-button;

    margin-top: $andes-spacing-32;
    color: $andes-blue-500;
  }
}
