@import '@andes/card/index';

.andes-card__header-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.accordion-item {
  &__card {
    border-radius: 0% !important;

    &-header {
      border-radius: 0% !important;
    }
  }

  button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-family: $font-family-primary;
  }

  &__left {
    &-border {
      &-color {
        border-left: 4px solid $andes-blue-500;
      }

      &-transparent {
        border-left: 4px solid transparent;
      }
    }
  }

  &__selected-title {
    color: $andes-blue-500;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    line-height: 22px;
  }

  &__title {
    color: $andes-gray-900;
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: 22px;
  }

  &__subtitle {
    color: $andes-gray-550;
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    line-height: 15px;
  }

  &__default-text {
    color: $andes-gray-900;
    font-size: $font-size-16;
    font-weight: $font-weight-light;
    line-height: 22px;
  }
}
