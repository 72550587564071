& {
  @import '../../Accordion/Accordion.style';

  &__container-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__divisor {
    margin-bottom: 32px;
  }

  &__container-accordion {
    display: flex;
    justify-content: center;
  }

  &__title {
    text-align: center;
  }

  .accordion-item {
    margin-bottom: 15px;
  }

  .accordion-item__left-border-color {
    border-left: none;
  }

  .accordion-item__card {
    border: 1px solid rgb(0 0 0 / 25%);
    border-radius: 6px !important;
  }

  .andes-card__header--border {
    border-bottom: none;
  }

  .andes-card--flat {
    box-shadow: none;
  }

  .accordion-item__left-border-transparent {
    border: none;
  }
}
