& {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0 6px 16px rgb(0 0 0 / 10%);
  border-radius: 18px;
  background-color: $andes-white;

  &_image {
    display: flex;
  }

  &_content {
    display: flex;
    flex-direction: column;
    text-align: left;

    &__title {
      font-size: $font-size-24;
      line-height: $line-height-m;
      font-weight: $font-weight-semibold;
    }

    &__description {
      font-size: $font-size-16;
      line-height: $line-height-m;
      margin-top: 30px;
    }

    &__subdescription {
      display: flex;
      align-items: center;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: $line-height-m;
      color: rgb(0 0 0 / 55%);

      .ico-desktop,
      .ico-mobile {
        display: inline-flex;
        width: 20px;
        height: 16px;
        margin-left: 2px;
        margin-right: 2px;
      }

      .ico-mobile {
        width: 12px;
        height: 20px;
      }

      p {
        margin-left: 6px;
      }
    }
  }
}
