.testimonials {
  @import './common';
  @import '../Item/styles/index.mobile';

  min-height: 850px;
  position: relative;
  top: -120px;
  padding-top: 30px;
  margin-bottom: -120px;

  &__content {
    margin-bottom: 60px;
  }

  &__content-title {
    padding-top: 37px;
    padding-bottom: 16px;
    max-width: 288px;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-24;
    line-height: 24px;
  }

  &__content {
    .andes-carousel-snapped__container {
      max-width: 336px;
    }

    .andes-carousel-snapped__controls-wrapper {
      max-width: 336px;
    }

    .andes-carousel-snapped__control {
      display: none;
    }

    .andes-carousel-snapped__pagination {
      margin-bottom: 80px;
    }
  }

  &__divisor {
    margin-top: 80px;
  }
}
