@import '@andes/message/index';
@import './common';

.section-top-message {
  margin-bottom: 30px;

  &__action {
    display: none;
  }

  .andes-message__text--neutral {
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
  }
}
