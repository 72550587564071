& {
  background-color: $andes-white;
  border-radius: 18px;

  &__content-description {
    font-weight: $font-weight-regular;
  }

  &__content-subdescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 32px;
    font-weight: $font-weight-regular;
  }

  &__content-subdescription-percentaje {
    padding-right: 6px;
    color: $ads-primary;
    font-weight: $font-weight-bold;
  }

  &__content-name {
    font-weight: $font-weight-bold;
    font-size: $font-size-20;
    line-height: 20px;
    padding-top: 24px;
  }

  &__content-city {
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    line-height: 16px;
    padding-top: 8px;
  }
}
