& {
  .img100 {
    width: 100%;
  }

  .wrap-smartphone {
    position: relative;
    z-index: 1;
  }

  .smartphone {
    width: 386px;
    height: 590px;
  }

  .position-ab {
    position: absolute;
  }

  .header-smartphone {
    width: 350px;
    height: 66px;
    top: 38px;
    left: 7px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px 10px 0 0;
  }

  .wrap-text-smartphone {
    left: 96px;
    top: 89px;
    overflow: hidden;
  }

  .text-input-smartphone {
    font-size: 12.3px;
    height: 20px;
  }

  .wrap-prods {
    display: flex;
    justify-content: center;
  }

  .prod-smartphone-1 {
    background-color: $andes-white;
    width: 85%;
    top: 135px;
  }

  .prod-smartphone-2 {
    background-color: $andes-white;
    width: 85%;
    top: 327px;
  }

  .prod-smartphone-3 {
    background-color: $andes-white;
    width: 85%;
    top: 489px;
  }

  .tittle-prod-smartphone {
    top: 16px;
    left: 130px;
    font-size: 13px;
    color: $andes-black;
  }

  .wrap-price {
    top: 37px;
    left: 130px;
    display: flex;
    align-items: center;
  }

  .price-prod-smartphone {
    font-size: 18.5px;
  }

  .bar-price-1 {
    width: 119px;
    height: 19px;
  }

  .image-prod {
    width: 95px;
    top: 20px;
    left: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-prod img {
    width: 100%;
  }

  .wrap-image-prod {
    background-color: transparent;
    padding: 5px 5px 5px 7px;
  }

  .justify-end {
    justify-content: end;
    padding-right: 5px;
  }

  .disclaimer-smartphone {
    font-size: 12.75px;
    color: #ab86ff;
    top: 136px;
    right: 9px;
  }

  .molecule {
    left: -70px;
    top: 50px;
    z-index: -5;

    img {
      max-height: 100%;
      max-width: inherit;
    }
  }

  .wrap-bg-white-smartphone {
    top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg-white-smartphone {
    width: 95%;
    height: 550px;
    z-index: -1;
    background-color: $andes-white;
    background: linear-gradient(
      to bottom,
      (rgb(255 255 255 / 100%) 88%),
      rgb(255 255 255 / 0%)
    );
  }

  .graddient-smartphone {
    width: 370px;
    height: 220px;
    left: 8px;
    bottom: -41px;
    background: rgb(255 255 255);
    background: linear-gradient(
      0deg,
      rgb(255 255 255 / 100%) 0%,
      rgb(255 255 255 / 0%) 100%
    );
    display: none;
  }

  .wrap-prod-1-svg {
    width: 100%;

    .st0 {
      fill: $andes-white;
    }

    .st1 {
      fill-opacity: 0.04;
    }

    .st2 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #4189e6;
      fill-opacity: 0.3;
    }

    .st3 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill-opacity: 0.04;
    }

    .st4 {
      fill: #00a650;
      fill-opacity: 0.1;
    }

    .st5 {
      fill-opacity: 0.07;
    }

    .st6 {
      fill: none;
      stroke: #ab86ff;
      stroke-width: 2.7331;
    }
  }

  .wrap-nav {
    animation: 2.6s linear 0s 1 normal forwards running
      gwd-gen-1gscgwdanimation_gwd-keyframes;
  }

  .text-input-smartphone {
    animation: 2s linear 0s 1 normal forwards running text-input;
  }

  .prod-smartphone-2 {
    animation: 3.8s linear 0s 1 normal forwards running anim-wrap-prod;
  }

  .prod-smartphone-3 {
    animation: 4.2s linear 0s 1 normal forwards running anim-wrap-prod;
    mask-image: linear-gradient(
      rgb(0 0 0 / 100%),
      rgb(0 0 0 / 10%),
      rgb(255 255 255 / 0%)
    );
  }

  .prod-smartphone-1 {
    animation: 4.5s linear 0s 1 normal forwards running anim-prod-1;
  }

  /* stylelint-disable-next-line selector-max-id */
  #anim-stroke {
    fill: none;
    stroke: #ab86ff;
    stroke-width: 2.7;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: 3.9s line-draw 2.3s ease-in-out alternate forwards;
  }

  @media (width <= 768px) {
    .wrap-smartphone {
      transform: scale(0.8);
    }
  }

  @media (width <= 576px) {
    .img-hero {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  /* Anim barNav */

  @keyframes gwd-gen-1gscgwdanimation_gwd-keyframes {
    0% {
      transform: translate3d(0, 40px, 0) scale3d(1.3954, 1.3831, 1);
      opacity: 0;
      animation-timing-function: ease-out;
    }

    14.2857% {
      transform: translate3d(0, 0, 0) scale3d(1.3954, 1.3831, 1);
      opacity: 1;
      animation-timing-function: linear;
    }

    75% {
      transform: translate3d(0, 0, 0) scale3d(1.3954, 1.3831, 1);
      opacity: 1;
      animation-timing-function: ease;
    }

    100% {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      opacity: 1;
      animation-timing-function: linear;
    }
  }

  /* anim text input */

  @keyframes text-input {
    0% {
      width: 0;
      animation-timing-function: ease-out;
    }

    14.2857% {
      width: 0;
      animation-timing-function: linear;
    }

    75% {
      width: 100px;
      animation-timing-function: ease;
    }

    100% {
      width: 100px;
      animation-timing-function: linear;
    }
  }

  /* Anim prod2 3 */

  @keyframes anim-wrap-prod {
    0% {
      transform: translate3d(0, -20px, 0);
      opacity: 0;
      animation-timing-function: linear;
    }

    76.4706% {
      transform: translate3d(0, -20px, 0);
      opacity: 0;
      animation-timing-function: ease;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      animation-timing-function: linear;
    }
  }

  /* Anim prod 1 */

  @keyframes anim-prod-1 {
    0% {
      transform: translate3d(0, -20px, 0) scale3d(1, 1, 1);
      opacity: 0;
      animation-timing-function: linear;
    }

    49.0566% {
      transform: translate3d(0, -20px, 0) scale3d(1, 1, 1);
      opacity: 0;
      animation-timing-function: ease;
    }

    64.1509% {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      opacity: 1;
      animation-timing-function: linear;
    }

    88.6792% {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      opacity: 1;
      animation-timing-function: ease;
    }

    100% {
      transform: translate3d(0, -10px, 0) scale3d(1.2137, 1.2137, 1);
      opacity: 1;
      animation-timing-function: linear;
    }
  }

  /* anim Line prod1 */

  @keyframes line-draw {
    0% {
      stroke-dashoffset: 2000;
      animation-timing-function: ease;
    }

    100% {
      stroke-dashoffset: 0;
      animation-timing-function: ease;
    }
  }

  /* end animations */
}
