.section-daily-budget {
  @import './common';

  min-height: 390px !important;
  background-size: cover !important;
  background-position: center;
  padding-bottom: 30px !important;
  position: relative;
  z-index: 7;
  top: -40px;
  margin-bottom: -40px;

  &__container {
    width: 290px;
    margin-top: 0;
  }

  &__title {
    font-size: $font-size-36 !important;
    line-height: $font-size-36;
    text-align: center;
  }

  &__description {
    font-size: $font-size-18 !important;
    line-height: $font-size-24;
    text-align: center;
    margin-bottom: 8px;
  }

  &__action {
    width: 100%;
    margin-top: 8px !important;
  }

  &-budget__divisor {
    padding: 30px 0 35px;
  }
}
