.section-hero {
  @import './common';

  background-image: url($storage-url + '/background_hero_wave.svg');
  min-height: 785px;
  background-size: contain !important;
  overflow-x: hidden;
  position: relative;
  z-index: 9;

  &__container {
    flex-direction: column;
    max-width: $ads-mobile-max-width;
  }

  &__image {
    // TODO
    max-width: min(100%, 488px);
    max-height: min(100%, 272px);

    &-container {
      margin-top: $andes-spacing-32;

      .header-smartphone {
        width: 326px;
        left: 8px;
      }

      .tittle-prod-smartphone {
        font-size: 12px;
      }

      .disclaimer-smartphone {
        top: 129px;
        right: 11px;
      }

      .graddient-smartphone {
        width: 344px;
      }
    }
  }

  &__content {
    max-width: 288px;

    &__title {
      font-size: $font-size-28;
      text-align: center;
    }

    &__description {
      line-height: 18px;
      text-align: center;
    }

    &__action {
      align-self: center;
    }
  }
}
